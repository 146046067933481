<template>
  <div class="container">
    <ul class="pagination">

      <li class="pagination-item" v-if="isShow">
        <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage">
          <i class="layui-icon layui-icon-left"></i>
        </button>
      </li>

      <!-- Visible Buttons Start -->

      <li v-for="page in pages" :key="page" class="pagination-item pagination-number">
        <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled" :class="{ active: isPageActive(page.name) }">
          {{ page.name }}
        </button>
      </li>

      <!-- Visible Buttons End -->

      <li class="pagination-item"  v-if="isShow">
        <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
          <i class="layui-icon layui-icon-right"></i>
        </button>
      </li>

    </ul>

  </div>

</template>

<script>
export default {
  name: "Pagination",
  data() {
    return {
      isShow:true,
    }
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 2
    },
    totalPages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;

    },
    endPage() {

      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);

    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    // onClickFirstPage() {
    //   this.$emit('pagechanged', 1);
    // },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    // onClickLastPage() {
    //   this.$emit('pagechanged', this.totalPages);
    // },
    isPageActive(page) {
      return this.currentPage === page;
    },
  }
}
</script>
<style scoped>
.pagination {
  list-style-type: none;
   display: flex;
  align-items: center;
  justify-content: center;
  margin:0 0 60px 0;
}

.pagination-item {
  display: inline-block;
  margin:0 8px;

}

.pagination-item button{
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid #ddd;
  cursor: pointer;
}
button:disabled{
  cursor: auto;
}

.active {
  background-color: #F88F00;
  border: 1px solid #F88F00;
  color: #ffffff;
  box-shadow: none;
}

@media only screen and (min-width:0px) and (max-width:991px){
  .pagination {
     margin:0 0 30px 0;
  }
}


</style>
