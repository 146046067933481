<template>
<div id="page-banner">
    <div class="banner-item">
      <div class="banner-title">
        <slot name="banner-txt"></slot>
      </div>
      <slot name="banner-img">
      </slot>
    </div>
</div>
</template>

<script>
export default {
  name: "PageBanner"
}
</script>

<style scoped>
#page-banner{
  padding-top: 92px;
}
.banner-item{
  position: relative;

}

.banner-title{
  position: absolute;
  left: 50%;
  width: 100%;
  top: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  color: #fff;
  font-size: 36px;
}
.banner-item img{
  max-width: 100%;
  display: block;
  margin: auto;
}

@media only screen and (min-width:767px) and (max-width:991px) {
  #page-banner{
    padding-top: 71px;
  }
}

@media only screen and (min-width:0px) and (max-width:991px) {
  .banner-title{
    font-size: 18px;
  }
  #page-banner{
    padding-top:68px;
  }
}



</style>