<template>
  <div class="container">
    <div class="compo-news-list">


          <news-item class="only-compo" v-for="item in news" :key="item" :newsItem="item"></news-item>



      </div>
  </div>

</template>

<script>
import NewsItem from "@/components/content/newsList/newsItem";

export default {
  name: "newsList",
  components: {
    NewsItem
  },
  props: {
    news: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped>
.container{
  padding: 0;
}
.compo-news-list{
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}


.only-compo{
  width: 31%;
  margin: 20px 1%;
}

@media only screen and (min-width:0px) and (max-width:767px) {
  .compo-news-list{
    padding: 20px 15px;
  }
  .only-compo{
    width: 100%;
    margin: 20px 0;
  }

}



</style>